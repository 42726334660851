import { useAsyncData, useRequestURL, useRoute, useRouteParams } from '#imports'

import type { _Block } from '@backmarket/http-api/src/api-specs-content/models/block'
import type { CategoryTreeProps } from '@backmarket/http-api/src/api-specs-content/models/category-tree'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'

import { useHandleFetchError } from '../../../cms/composables/useHandleFetchError'
import { transformHref } from '../../../cms/helpers/link/transformHref'
import { CMS } from '../../../cms/routes-names'

function formatCategoryTreeLinks(
  categoryTree: _Block<'CategoryTree'>,
  currentUrl: URL,
): CategoryTreeProps {
  return {
    ...categoryTree.props,
    categoryTree: categoryTree?.props?.categoryTree?.map((ce) => {
      return {
        ...ce,
        link: transformHref({
          href: ce.link.href || '',
          currentUrl,
        }),
        subCategories: ce.subCategories.map((sc) => {
          return {
            ...sc,
            link: transformHref({
              href: sc.link.href || '',
              currentUrl,
            }),
          }
        }),
      }
    }),
  }
}

export async function useFetchContentHub() {
  const $httpFetch = useHttpFetch()
  const routeParams = useRouteParams()
  const route = useRoute()
  const currentUrl = useRequestURL()

  const { data, error } = await useAsyncData(async () => {
    const pageCategory =
      route.name === CMS.ARTICLE_ROOT_HUB ? 'content' : routeParams.pageCategory

    return $httpFetch('/content/page/article-category/:pageCategory', {
      pathParams: { pageCategory },
    })
  })

  useHandleFetchError(error)

  if (data.value) {
    data.value.categoryTree.props = formatCategoryTreeLinks(
      data.value.categoryTree,
      currentUrl,
    )
  }

  return data
}
